  
<template>
    <section>
        <p> Hier kunt u Excel bestanden uploaden (alleen ".xlsx" of ".xls" bestanden)</p>
        <form>
          <input type="file" ref="excelfile" name="excelfile" @change="handleFileUpload"/>
        </form>
        <p>{{ message }}</p>
    </section>
</template>
 
<script>
import * as XLSX from "xlsx";
import AppointmentDataService from "../../services/AppointmentDataService";
import moment from 'moment';

export default {
  data() {
    return {
      uploadedFile: "",
      errors: [],
      message: "",
      excelData: [],
      appointment: {
          AppointmentNumber: "",
      },
      appointments: [],
    };
  },
  methods: {
      checkIfExitsts(item, appointments) {
          for (var i = 0; i < appointments.length; i++) {
              if(appointments[i].AppointmentNumber == item["WOnummer"]) {
                  console.log("Nummer found");
                  return true;
              }
          }
          return false;
      },
      uploadData(excelData, appointments) {
          // console.log(excelData);
          // console.log(appointments);
          let exists = false;
          let count = 0;
          excelData.forEach(item => {
            exists = this.checkIfExitsts(item, appointments);
            if(exists == false) {
                count++;
                this.appointment = {
                    AppointmentNumber: item["WO-nummer"], 
                    Contractor: item["Opdrachtgever"], 
                    Description: item["Omschrijving"], 
                    DueBy: item["Gereed voor"], 
                    CustomerNumber: item["Nummer klant"],
                    Finished: "0",
                    GeneralProblem: "",
                    PlannendWorkDate: Math.round((item["Datum gepland"] - 25569) * 86400), 
                    RegistrationDate : Math.round((item["Registratiedatum"] - 25569) * 86400),
                    Status: item["Status"],
                    TelNo1: item["Telefoon 2 werkadres"],
                    TelNo2: item["Telefoonnr."],
                    Timestamp: moment().format("X"),
                    VideoAppointment: "No video appointment",
                    WorkAddress: item["Werkadres"],
                    WorkLocation: item["Plaats"],
                    WorkType: item["Soort"],
                };

                // Clean any default undefined values
                let cleanAppointment = JSON.stringify(this.appointment, function (key, value) {return (value === "") ? undefined : value});
                // console.log(cleanAppointment);
                AppointmentDataService.create(JSON.parse(cleanAppointment));
            }
          });
          this.message = "Success, er zijn " + count + " Afspraken aangemaakt"; 
      },
      handleData(items) {
          let _appointments = [];

          items.forEach(element => {
              let key = element.key;
              let dataVal = element.val();

              var data = JSON.parse(JSON.stringify(dataVal))
              _appointments.push ({
                  key: key,
                  AppointmentNumber: data.AppointmentNumber
              });
          });

          this.appointments = _appointments;
          return this.appointments;
        },
      handleFileUpload(event) {
            var input = event.target;
            var reader = new FileReader();
            
            // Get list items to check if appointment Number exists
            AppointmentDataService.getAll().on("value", this.handleData);

            reader.onload = () => {
                var fileData = reader.result;
                var wb = XLSX.read(fileData, {type : 'binary'});
                wb.SheetNames.forEach((sheetName) => {
                    var rowObj =XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);	

                    this.uploadData(rowObj, this.appointments);
  
                this.excelData = JSON.stringify(rowObj)
                })
            };
            reader.readAsBinaryString(input.files[0]);  
            
      },
      Upload() {
          console.log("Upload");
      }
  }
  /*
  methods: {
    handleFileUpload() {
      this.uploadedFile = this.$refs.excelfile.files[0];
    },
    Upload() {
      const formData = new FormData();
      formData.append("file", this.uploadedFile);
      // TODO: Implement this logic

      axios.post(`/excel/upload/`, formData, {headers: { "Content-Type": "multipart/form-data"}})
      .then(response => {
          this.message = "Succes! " + response.data + " nieuwe afspraken aangemaakt.";
      })
      .catch(e => {
          this.errors.push(e)
          this.message = "Er is wat fout gegaan; probeer opnieuw";
      })
    }
  }
*/
} 
</script>